import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import { sports } from '../../../../helpers/constant';

export default function SimpleTabs() {

  const classes = useStyles();
  const history = useHistory();
  const [userlist, setUserlist] = useState([]);
  const usertype = useSelector(state => state.auth.usertype)

  useEffect(() => {
    const users = [
      { type: 'seniorsuper', usertype: '2' },
      { type: 'supermaster', usertype: '3' },
      { type: 'master', usertype: '4' },
      { type: 'client', usertype: '5' }]
    const listuser = users.slice(parseFloat(usertype) - 1)
    setUserlist(listuser)
  }, [usertype])

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.navbar}>
        <div
          className={classes.tabroot}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={() => { history.push('/dashbard') }}>dashboard</MenuItem>
          {usertype !== '5' ?
            <>
              <div className={classes.subMenuParent} tab-index="0">
                <span className={classes.span}><i className="fas fa-users" style={{ padding: '0 4px' }}></i>users</span>
                <ul className={classes.subMenu}>
                  {userlist.map(user => {
                    return <li
                      className={classes.li}
                      key={user.usertype}
                      onClick={() => {
                        history.push("/userlist/" + user.usertype)
                      }}>
                      {user.type}
                    </li>
                  })}
                </ul>
              </div>
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  history.push('/blockMarket')
                }}>Block Market
              </MenuItem>
            </> : null}
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                history.push('/runningMarketAnalysis')
              }}>Market Analysis
            </MenuItem>
            {sports.filter(sport=>sport.id!=='6').map(sport=>{
              return <MenuItem
                key={sport.id}
                className={classes.menuItem}
                onClick={() => {
                  history.push(`/sport/${sport.id}`)
                }}>{sport.name}
              </MenuItem>
            })}
        </div>
      </AppBar>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'var(--header_color)'
  },
  navbar: {
    top: '84px',
    textTransform: 'none',
    position: 'fixed',
    zIndex: '10',
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  tabroot: {
    minHeight: '30px',
    display: 'flex',
    backgroundColor: 'var(--header_color)',
    fontWeight: '700'
  },
  menuItem:{
    fontWeight: '700',
    fontSize: 'var(--large_font)',
    textTransform: 'capitalize',
    "&:hover": {
      background: 'var(--heading)',
    }
  },
  subMenu: {
    visibility: 'hidden',
    opacity: 0,
    position: 'absolute',
    left: 0,
    width: '10em',
    transform: 'translateY(-2em)',
    zIndex: -1,
    transition: 'all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s',
    background: 'white',
    textAlign: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    color: '#1e1e1e'
  },
  subMenuParent: {
    position: 'relative',
    '&:hover': {
      "& $subMenu": {
        visibility: 'visible',
        opacity: 1,
        zIndex: 'auto',
        transform: 'translateY(0%)',
        transitionDelay: '0s, 0s, 0.3s',
      }
    }
  },
  span: {
    color: 'white',
    display: 'block',
    padding: '0.5em 1em',
    textDecoration: 'none',
    cursor: 'pointer',
    textTransform: 'capitalize',
    "&:hover":
    {
      color: 'white',
      background: 'var(--heading)',
    }
  },
  li: {
    textDecoration: 'none',
    listStyleType: 'none',
    padding: '5px 0',
    margin: '0',
    color: '#1e1e1e',
    cursor: 'pointer',
    fontWeight: '500',
    textTransform: 'capitalize',
    "&:hover": {
      background: 'var(--heading)',
    }
  }
}));
