import React, { useEffect, useState } from 'react'
import axios from '../../../../axios-instance/backendAPI'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@material-ui/core";
import { useSelector } from 'react-redux';
import { formatCoins, redirectToFullMarket } from '../../../../helpers/common';
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    table: {
        overflow: "scroll",
        '& .MuiTableCell-root': {
            fontSize: 'var(--large_font)', // Adjust the font size as needed
        },
        [theme.breakpoints.down("sm")]: {
            overflow: "auto",
            '& .MuiTableCell-root': {
                fontSize: 'var(--small_font)', // Adjust the font size as needed
            },
        },
    },
}))

export default function RunningMarketAnalysis() {

    const classes = useStyles();
    const history = useHistory();
    const [markets, setMarkets] = useState([])
    const token = useSelector(state => state.auth.token)

    useEffect(() => {

        axios.get('/user/runningMarketAnalysis', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                if (response.data.success) {
                    setMarkets(response.data.data)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <div>
            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableBody>
                        {markets.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={4} align='center'>No data</TableCell>
                            </TableRow>
                        ) : (
                            Object.entries(markets.reduce((acc, market) => {
                                if (!acc[market.sport]) acc[market.sport] = [];
                                acc[market.sport].push(market);
                                return acc;
                            }, {})).map(([sport, sportMarkets]) => (
                                <React.Fragment key={sport}>
                                    <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                        <TableCell colSpan={3} align='center' style={{ fontWeight: '700' }}>{sport}</TableCell>
                                    </TableRow>
                                    {Object.entries(sportMarkets.reduce((acc, market) => {
                                        if (!acc[market.event]) acc[market.event] = [];
                                        acc[market.event].push(market);
                                        return acc;
                                    }, {})).map(([event, eventMarkets]) => (
                                        <React.Fragment key={event}>
                                            <TableRow style={{ backgroundColor: '#e0e0e0' }}>
                                                <TableCell
                                                    colSpan={4}
                                                    align='start'
                                                    style={{ cursor: 'pointer', color: 'blue' }}
                                                    onClick={() => redirectToFullMarket(history, eventMarkets[0].event_id)}
                                                >
                                                    {event}
                                                </TableCell>
                                            </TableRow>
                                            {eventMarkets.map((market) => (
                                                <TableRow key={market.market_id}>
                                                    <TableCell align='center' style={{ fontWeight: '600'}}>{market.market}</TableCell>
                                                    <TableCell style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column' }}>
                                                        {market.runners.map(runner => (
                                                            <div key={runner.selectionId}>
                                                                <p>{runner.name} (<span style={{ fontWeight: '700', color: runner.net_profit >= 0 ? 'green' : 'red' }}>{formatCoins(runner.net_profit_wc)}</span>)</p>
                                                            </div>
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
