import axios from "axios";

export const localStorageGetItem = (key) => {
  let result = '';
  try {
    if (typeof window === 'object' && window.localStorage) {
      result = window.localStorage.getItem(key);
    }
    return result;
  } catch (error) {
    result = '';
  }
  return result;
};

export const dateFormatter = (date) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }

  return new Date(date).toLocaleDateString(undefined, options)
}

export const parseDateString = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date)) {
    return { error: "Invalid date string" };
  }

  const options = { month: 'short', day: 'numeric' };
  const datePart = date.toLocaleDateString('en-IN', options);
  const timePart = date.toLocaleTimeString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true });

  return {
    date: datePart,
    time: timePart
  };
}

export function getDeviceType() {
  const ua = navigator.userAgent;

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
}

export function redirectToFullMarket(history, event_id) {
  if (history) {
    history.push(`/fullmarket/${event_id}`)
  } else {
    window.location.href = `/fullmarket/${event_id}`
  }
}

export function reloadPage() {
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}

export function formatCoins(str, maximumFractionDigits = 2) {
  const locale = 'en-IN';
  return str?.toLocaleString(locale, {
    maximumFractionDigits: maximumFractionDigits
  })
}

export function transformToBookmakerOdds(odds) {

  // Subtract 1 from the number
  let result = odds - 1;

  // Multiply the result by 100
  result = result * 100;

  // Handle floating point precision
  result = Math.round(result * 1000000) / 1000000;

  return result;
}

export function transformToOriginalOdds(bookmakerOdds) {
  // Divide the input by 100
  let result = bookmakerOdds / 100;

  // Add 1 to the result
  result = result + 1;

  // Handle floating point precision
  result = Math.round(result * 1000000) / 1000000;

  return result;
}

// new Date(event.event_timestamp_date * 1000).getTime() === new Date(openDate)
export async function getSportsRadarId(eventId) {

  try {
    const response = await axios.get(`https://zplay1.in/sports/api/v1/events/matchDetails/${eventId}`)
    if (response.data.success) {
      return response.data.data.match.sportsradar_id
    }
    return ""
  } catch (err) {
    console.log(err)
    return ""
  }

}

export const getTvUrl = async (eventId, sport) => {
  try{
    const response = await axios.get(`/api/getTVURL/${eventId}/${sport}`)
    if (response.data.success) {
      return response.data.data.iframeUrl
    }
    return null
  } catch(err){
    console.log(err)
    return null
  }
}
