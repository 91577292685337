import React, { useEffect, useState, forwardRef} from "react";
import MarketTable from "../MarkeTable";

const BetfairMarkets = forwardRef((props,ref) => {

    const [odds, setOdds] = useState(null)
    const socket = props.socket

    useEffect(() => {

        if(socket){

            const key = 'Event/Auto'
            
            socket.on(`${key}/${props.eventId}`, (data) => {
                setOdds(data)
            });

            const marketIds = props.markets.map(market => market.id).join(',')

            //Subscribe to the market        
            socket.emit(key, {eventId: props.eventId, marketId: marketIds});
        }

    }, []);

    return props.markets.map((market, index) => {
            return <MarketTable
                key={market.id}
                order={market.name === 'Match Odds' ? 0 : index + 2}
                marketId={market.id}
                name={market.name}
                adv_max={market.adv_max}
                max={market.max}
                min={market.min}
                runners={market.runners}
                startTime={market.marketStartTime}
                odds={odds?.filter(marketOdds => marketOdds.marketId === market.id)}
                ref={ref}
                {...props}
            />
        })
});

export default BetfairMarkets