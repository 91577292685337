import React, { useEffect, useState, forwardRef} from "react";
import MarketTable from "../MarkeTable";

const BookmakerMarket = forwardRef((props,ref) => {

    const [odds, setOdds] = useState(null)
    const socket = props.socket

    useEffect(() => {

        if(socket){

            const key = 'Bookmaker/Auto'
            
            socket.on(`${key}/${props.eventId}`, (data) => {
                setOdds(data)
            });

            //Subscribe to the market        
            socket.emit(key, {eventId: props.eventId, marketId: props.oddsMarketId});
        }

    }, []);

    return props.markets.map(market => {
            return <MarketTable
                key={market.id}
                order={1}
                marketId={market.id}
                name={market.name}
                adv_max={market.adv_max}
                max={market.max}
                min={market.min}
                runners={market.runners}
                startTime={market.marketStartTime}
                odds={odds}
                ref={ref}
                {...props}
            />
        })
});

export default BookmakerMarket