import React, { useState, useRef, useEffect } from "react";
import Market from "./Market/Market";
import BetSlip from "./BetSlip/BetSlip";
import { makeStyles } from "@material-ui/core/styles";
import LiveTv from "./LiveTv";
import config from "../../../../config";
import io from 'socket.io-client'
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "60% 40%",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
}));

export default function Fullmarket() {
  const [flag, setFlag] = useState(false)
  const [stakeChanged, setStakeChanged] = useState(false)
  const classes = useStyles()
  const [event, setEvent] = useState({
    eventId: "",
    marketId: "",
    marketName: ""
  });
  const [socket, setSocket] = useState()
  const [sport, setSport] = useState('')
  const params = useParams()
  const ref = useRef(null)

  useEffect(() => {
    const url = config.NODE_ENV === 'development' ? config.SOCKET_DEV_URL : config.SOCKET_URL
    const newSocket = io(url, {
      'transports': ['websocket'],
      'allowEIO4': true,
      'reconnection': true,
      'reconnectionDelay': 1000,
      'reconnectionDelayMax': 5000,
      'reconnectionAttempts': 5
    })
    setSocket(newSocket)
    return () => {
      newSocket.disconnect()
    }
  }, [params.matchId])

  const changeFlag = () => {
    setFlag(prevValue => {
      return !prevValue
    })
  }

  const changeStakeChanged = () => {
    setStakeChanged(prevValue => {
      return !prevValue
    })
  }

  const changeMarket = (marketId, eventId, marketName) => {
    setEvent({
      eventId: eventId,
      marketId: marketId,
      marketName: marketName
    });
  };

  return (
    <div className={classes.root}>
      <div>
        <Market
          changeMarket={changeMarket}
          changeFlag={changeFlag}
          stakeChanged={stakeChanged}
          ref={ref}
          socket={socket}
          setSport={setSport}
        />
      </div>
      <div>
        <LiveTv matchId={params.matchId} sport={sport}/>
        <BetSlip
          ref={ref}
          display="block"
          marketId={event.marketId}
          eventId={event.eventId}
          marketName={event.marketName}
          flag={flag}
          changeStakeChanged={changeStakeChanged}
        />
      </div>
    </div>
  );
}